@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600&display=swap');

.centerFullScreen {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	height: 100vh;
}

body {
	font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
		'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
		'Segoe UI Symbol';
	font-weight: 400;
	font-size: 14px;
}
h1 {
	font-family: 'kanit', sans-serif;
	font-size: 48px;
	font-weight: 700;
}
h2 {
	font-size: 22px;
	font-weight: 500;
}
h3 {
	font-size: 18px;
	font-weight: 700;
}
h4 {
	font-size: 12px;
	font-weight: 700;
}
h5 {
	font-size: 10px;
	font-weight: 500;
}
p {
	font-size: 14px;
	font-weight: 400;
}

.custom-switch {
	width: 79.9px;
	height: 39.1px;
	box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.1);
	background-color: #9da5b5;
}
.custom-switch.ant-switch-checked {
	background-color: #0384ce;
}
.custom-switch::after {
	width: 32px;
	height: 32px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
	background-color: #fdfefe;
	border-radius: 50%;
	top: 2px;
}

// HighlightText
mark {
	padding: 0;
	background-color: #ffe58f;
}

// button dropdown and breadcrumb dropdown

.ant-dropdown-menu {
	border-radius: 5px;
	box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
	background-color: #ffffff;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
	clear: both;
	margin: 0;
	padding: 5px 12px;
	color: #9b9b9b;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	white-space: nowrap;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
	font-size: 14px;
	color: #0ea661;
	background-color: rgba(14, 166, 97, 0.1);
}

.ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-submenu-title > a:hover {
	color: #0ea661;
}

.ant-breadcrumb a {
	color: #069b3b;
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
}

.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
	font-size: 14px;
	color: #9b9b9b;
}

// input disabled
.ant-input[disabled] {
	color: rgba(31, 38, 62, 0.25);
	background-color: rgba(193, 193, 193, 0.3);
	opacity: unset;
}

.myFilter {
	.ant-popover-inner-content {
		width: auto;
		min-width: 320px;
		padding: 10px 0px 0px 0px;
	}
}

// option select areachart
.ant-select-item {
	font-size: 12px;
}

// button disable
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
	color: #ffffff;
	background-color: rgba(193, 193, 193, 0.8);
	border-color: transparent;
}

// Drawer Note
.ant-drawer-mask {
	background-color: unset;
}
.ant-drawer-body {
	padding: 20px;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
	width: 75%;
	@media only screen and (min-width: 1080px) {
		width: 35%;
	}
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
	-webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
}

// Supporter
.slick-slider .slick-list,
.slick-slider .slick-track {
	display: flex;
	align-items: center;
}
